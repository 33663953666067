
/* jdev seb test v2 app javascripts */

(function($) {
	
	'use strict';

	var isMobile = {
		Android: function() {
			return navigator.userAgent.match(/Android/i);
		},
			BlackBerry: function() {
			return navigator.userAgent.match(/BlackBerry/i);
		},
			iOS: function() {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
			Opera: function() {
			return navigator.userAgent.match(/Opera Mini/i);
		},
			Windows: function() {
			return navigator.userAgent.match(/IEMobile/i);
		},
			any: function() {
			return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
		}
	};


	// Avoid `console` errors in browsers that lack a console.
	var avoidConsoleErrors = function() {
	    var method;
	    var noop = function noop() {};
	    var methods = [
	        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
	        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
	        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
	        'timeStamp', 'trace', 'warn'
	    ];
	    var length = methods.length;
	    var console = (window.console = window.console || {});

	    while (length--) {
	        method = methods[length];

	        // Only stub undefined methods.
	        if (!console[method]) {
	            console[method] = noop;
	        }
	    }
	}

	var mobileMenuOutsideClick = function() {

		$(document).click(function (e) {
	    var container = $("#nav-offcanvas, .js-nav-toggle");
		    if (!container.is(e.target) && container.has(e.target).length === 0) {
		    	if ( $('body').hasClass('offcanvas') ) {
	    			$('body').removeClass('offcanvas');
	    			$('.js-nav-toggle').removeClass('active');
		    	}
		    }
		});

	};

	var scrollNavBar = function() {

		if ( $(window).scrollTop() > 50)  {
			$('body').addClass('scrolled');
			$('.js-nav-toggle').removeClass('fh5co-nav-white');
			//console.log("scrolled down");
		} else {
			$('body').removeClass('scrolled');
			$('.js-nav-toggle').addClass('fh5co-nav-white');
		}

	};

	var offcanvasMenu = function() {

		$('#page').prepend('<div id="nav-offcanvas" />');
		//$('#page').prepend('<a href="#" class="js-nav-toggle hamburger-toggle"><i></i></a>');
		var clone2 = $('#top-bar .phone a').clone();
		$('#nav-offcanvas').append(clone2);		
		var clone3 = $('#features .quick-links ul').clone();
		$('#nav-offcanvas').append(clone3);
		var clone1 = $('#footer .quick-links').clone();
		$('#nav-offcanvas').append(clone1);

		$('#nav-offcanvas .has-dropdown').addClass('offcanvas-has-dropdown');
		$('#nav-offcanvas')
			.find('li')
			.removeClass('has-dropdown');

		//clone header button
		// var buttonsClone = $('#topBar .header-buttons').clone();
		// $('#nav-offcanvas').append(buttonsClone);


		// Hover dropdown menu on mobile
		$('.menu-item-has-children').mouseenter(function(){
			var $this = $(this);

			$this
				.addClass('active')
				.find('ul')
				.stop().slideDown('fast', '');
		}).mouseleave(function(){

			var $this = $(this);
			$this
				.removeClass('active')
				.find('ul')
				.stop().slideUp('fast', '');
		});		


		// Click dropdown menu on mobile
		$('.has-sub-menu').click(function(){
			var $this = $(this);

			$this
				.toggleClass('active')
				.find('ul')
				.stop().slideToggle('fast');
		});


		$(window).resize(function(){

			if ( $('body').hasClass('offcanvas') ) {

    			$('body').removeClass('offcanvas');
    			$('.js-nav-toggle').removeClass('active');
				
	    	}
		});
	};

	var burgerMenu = function() {
		$('body').on('click', '.js-nav-toggle', function(event){
			var $this = $(this);


			if ( $('body').hasClass('overflow offcanvas') ) {
				$('body').removeClass('overflow offcanvas');
			} else {
				$('body').addClass('overflow offcanvas');
			}
			$this.toggleClass('active');
			event.preventDefault();

		});
	};

	var dropdown = function() {
		$('.has-dropdown').mouseenter(function(){

			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'block')
				.addClass('animated-fast fadeInUpMenu');
		}).mouseleave(function(){
			var $this = $(this);
			$this
				.find('.dropdown')
				.css('display', 'none')
				.removeClass('animated-fast fadeInUpMenu');
		});

	};


	var navToggleColorEffect = function() {
		$( "button.menu-toggle" ).click(function() {
			console.log('button clicked');
			event.stopPropagation();

		});				
	}


	function smoothScroll() {
	// Select all links with hashes and class 'smoothAnchor'
		$('.smoothAnchor')
	// Remove links that don't actually link to anything
		// .not('[href="#"]')
		// .not('[href="#0"]')
		.click(function(event) {
	// On-page links
		if (
			location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
			&& 
			location.hostname == this.hostname
		) {
			  // Figure out element to scroll to
			  var target = $(this.hash);
			  target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			  // Does a scroll target exist?
			  if (target.length) {
			    // Only prevent default if animation is actually gonna happen
			    event.preventDefault();
			    $('html, body').animate({
			      scrollTop: target.offset().top
			    }, 400, function() {
			      // Callback after animation
			      // Must change focus!
			      var $target = $(target);
			      $target.focus();
			      if ($target.is(":focus")) { // Checking if the target was focused
			        return false;
			      } else {
			        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
			        $target.focus(); // Set focus again
			      };
			    });
			  }
			}
		});

	}




	$(function(){
		mobileMenuOutsideClick();
		scrollNavBar();
		offcanvasMenu();
		burgerMenu();
		dropdown();		
		navToggleColorEffect();
		smoothScroll();
		//initWayPoints();

	});


	 $(window).scroll(function(){
		scrollNavBar();
	 });

	 $(window).resize(function(){
		//function here
	 });



//END of scripts

})(jQuery); // Fully reference jQuery BEYOND this point.


(function($) {
	
	// VIC/TAS/QLD/WA/SA/NT 
	var locations1 = ['vic', 'tas', 'qld', 'wa', 'sa', 'nt'];
	// NSW/ACT
	var locations2 = ['nsw', 'act'];

	function stateToggleEvent() {
		$('#selecState').on('change',function(){
			var selectedState = $("#selecState option:selected").val();
			switchBDMdata(selectedState);
		});
	};
	
	function showOnlyPartner() {
		var partnerID = $('#bdmInfo').attr('data-attr-partner');
		
		if(partnerID == 'referral' || partnerID == 'referaclient' || partnerID == 'belconnen') {
			$('#bdmMargie').addClass('d-none');
			$('#bdmDaniel').removeClass('d-none');
		}
		if(partnerID == 'referaclient'||partnerID == 'belconnen') {
			$('#colSelectState').hide();
		}
	}

	function switchBDMdata(s) {
		if (jQuery.inArray(s, locations2) != -1) {
			$('#bdmMargie').removeClass('d-none');
		} else {
			$('#bdmMargie').addClass('d-none');
		}
		
		if (jQuery.inArray(s, locations1) != -1) {
			$('#bdmDaniel').removeClass('d-none');
		} else {
			$('#bdmDaniel').addClass('d-none');
		}
		
		if (s == '') {
			$('#bdmDaniel').removeClass('d-none');
			$('#bdmMargie').removeClass('d-none');
		}
	};

	$(function(){
		stateToggleEvent();
		showOnlyPartner();
	});
})(jQuery); 



(function($) {
	
	var refUrl = document.referrer;

	console.log('referrer:' + refUrl);


	function getReferrerDomain() {
	    var url = document.createElement('a');
	    url.href = document.referrer;
	    return url.hostname;
	}

	console.log('referrer fx:' + getReferrerDomain() );
	var refUrl =  refUrl.match(/:\/\/(.[^/]+)/);
	console.log('referrer parsed:' + refUrl );


	var partnerString = '&partnerid=aussie';
	console.log('debug string:' + partnerString );
	//Loop through every iframe in .frame-wrap
	$.each($('#conciergeForm').find('iframe'), function(index, i)
	{
	    //set url equal to this iteration's iframe's src
	    var url = $(i).attr('src');
	   //set this iteration's iframe's src equal to url plus the hideLinkHeader global variable
	   $(i).attr('src', url + partnerString);
	   console.log('debug string:' + partnerString );
	});


//END of scripts

})(jQuery); // Fully reference jQuery BEYOND this point.


